import styled, { css } from 'styled-components'
import { VoiceButtonTooltip } from 'src/components/VoiceButton/VoiceButton.styles'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.isMobile ? '10px 16px' : '14px 100px')};
  border-bottom: 1px solid ${props => props.theme.colors.headerBorderColor};
  margin-bottom: 16px;
  background-color: ${props => props.theme.colors.headerBackground};
  
  ${props => !props.isMobile && css`
    @media (max-width: 920px) {
      padding: 28px;
    }
  `}
`

export const ControlButton = styled.button`
  background-color: ${props => props.theme.colors.transparent};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: ${props => (props.isMobile ? 28 : 40)}px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.additionalText};
  cursor: pointer;
  
  &:last-child {
    margin-right: 0;
  }
`

export const ControlPanelContainer = styled.div`
  display: flex;
  position: relative;
`

export const ControlButtonTooltip = styled(VoiceButtonTooltip)`
  bottom: -70px;
  right: ${props => (props.isMobile ? props.isAndroid ? 0 : 50 : 264)}px;

  &:after {
    top: unset;
    bottom: 100%;
    left: ${props => (props.isMobile ? '109px' : '50%')};
    border-top: none;
    border-bottom: 8px solid ${props => props.theme.colors.tooltipBackgroundColor};
  }

  ${props => !props.isMobile && css`
    @media (max-width: 720px) {
      right: 28px;
    }
  `}
`

export const LogoContainer = styled.div`
  flex-grow: 1;
  
  & img {
    display: block;
    width: ${props => (props.isMobile ? 77 : 124)}px;
    height: ${props => (props.isMobile ? 22 : 34)}px;
  }
`

export const ButtonTitle = styled.p`
  margin: 0 8px 0 0;

  ${props => !props.isMobile && css`
    @media (max-width: 720px) {
     display: none;
    }
  `}
`
