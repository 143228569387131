import React, { useRef, useMemo, useState, useEffect, Dispatch, FC, SetStateAction } from 'react';
import { Widget } from 'src/pages/Widget/Widget';
import { SwitchTabStyled } from 'src/pages/SwitchTab/SwitchTab.styled';
import { QuestionnairesPage } from 'src/pages/QuestionnairesPage/QuestionnairesPage';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { audioTracks, PostMessagesTypes, Tabs } from 'src/constans/constans';
import FamilyTreeModal from 'src/pages/FamilyTreePage/FamilyTreeModal/FamilyTreeModal';
import SwitchTabHeader from 'src/pages/SwitchTab/SwitchTabHeader';
import { FamilyTreeSettingsInterface } from 'src/models/RiskAssessmentQuestionnaire';

interface Props {
  widgetSource: MessageEventSource
  setWidgetSource: Dispatch<SetStateAction<MessageEventSource>>
  setIsShowVoiceButton: Dispatch<SetStateAction<boolean>>
  botTypePreference: string
  logOut: () => void
  updatingAudioQueue: (data: string) => void
  stopPlayAndResetAudioQueue: () => void
  stopSpeakingUtterance: () => void
  setIsWidgetStartedPlay: Dispatch<SetStateAction<boolean>>
  setIsWidgetFinishedPlay: Dispatch<SetStateAction<boolean>>
  isWidgetFinishedPlay: boolean
  isWidgetStartedPlay: boolean
}

export const SwitchTab: FC<Props> = ({
  widgetSource,
  setWidgetSource,
  setIsShowVoiceButton,
  botTypePreference,
  logOut,
  updatingAudioQueue,
  stopPlayAndResetAudioQueue,
  stopSpeakingUtterance,
  setIsWidgetStartedPlay,
  setIsWidgetFinishedPlay,
  isWidgetFinishedPlay,
  isWidgetStartedPlay
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const { activeTab, setActiveTab } = useActiveTab();
  const isWidgetActiveTab = useMemo(() => activeTab === Tabs.TAB_1, [activeTab])
  const [prevTab, setPrevTab] = useState(activeTab)
  const [familyTreeSettings, setFamilyTreeSettings] = useState<FamilyTreeSettingsInterface>({
    isOpen: false,
    isLoading: false
  })
  const reactFlowRef = useRef(null)

  const sendVoiceSpeakingMessage = (currentTab, isManualClick: boolean) => {
    if (isManualClick) {
      stopPlayAndResetAudioQueue()
      stopSpeakingUtterance()
    }
    widgetSource.postMessage({
      type: PostMessagesTypes.WIDGET_SAY_UTTERANCE,
      audio: currentTab === Tabs.TAB_1 ? audioTracks.switchTabChat : audioTracks.switchTabQuestionnaire
    }, '*' as WindowPostMessageOptions)
  }

  const switchTabHandler = (currentTab: Tabs) => {
    setActiveTab(currentTab)
    setPrevTab(currentTab)
    widgetSource.postMessage({
      type: PostMessagesTypes.WIDGET_TAB_SWITCHING,
      currentTab
    }, '*' as WindowPostMessageOptions)

    if (activeTab === currentTab) return
    sendVoiceSpeakingMessage(currentTab, true)
  }

  useEffect(() => {
    if (activeTab !== prevTab) {
      sendVoiceSpeakingMessage(activeTab, false)
    }
  }, [activeTab, prevTab]);

  useEffect(() => {
    widgetSource?.postMessage({
      type: PostMessagesTypes.WIDGET_TAB_SWITCHING,
      currentTab: activeTab
    }, '*' as WindowPostMessageOptions)
  }, [widgetSource, activeTab]);

  return (
    <SwitchTabStyled
      isMobile={isMobile}
      isWidgetActiveTab={isWidgetActiveTab}
    >
      <SwitchTabHeader
        isWidgetActiveTab={isWidgetActiveTab}
        switchTabHandler={switchTabHandler}
      />
      <div className="outlet">
        <Widget
          isWidgetActiveTab={isWidgetActiveTab}
          setWidgetSource={setWidgetSource}
          setIsShowVoiceButton={setIsShowVoiceButton}
          logOut={logOut}
          setIsWidgetStartedPlay={setIsWidgetStartedPlay}
          setIsWidgetFinishedPlay={setIsWidgetFinishedPlay}
        />
        <QuestionnairesPage
          isWidgetActiveTab={isWidgetActiveTab}
          botTypePreference={botTypePreference}
          familyTreeSettings={familyTreeSettings}
          setFamilyTreeSettings={setFamilyTreeSettings}
          widgetSource={widgetSource}
          updatingAudioQueue={updatingAudioQueue}
          stopPlayAndResetAudioQueue={stopPlayAndResetAudioQueue}
          stopSpeakingUtterance={stopSpeakingUtterance}
          setIsWidgetFinishedPlay={setIsWidgetFinishedPlay}
          isWidgetFinishedPlay={isWidgetFinishedPlay}
          isWidgetStartedPlay={isWidgetStartedPlay}
        />
        {familyTreeSettings.isOpen && <FamilyTreeModal
          reactFlowRef={reactFlowRef}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />}
      </div>
    </SwitchTabStyled>
  )
}
