import React, { Dispatch, FC, SetStateAction, useEffect, useRef} from 'react'
import { useLocalStorage } from 'usehooks-ts'
import {
  AttachmentState,
  CustomAttributes,
  QuestionnairesTypes,
  Tabs
} from 'src/constans/constans'
import { useActiveTab } from 'src/hooks/useActiveTab'

import { WidgetContainer } from './Widget.styles'
import { widgetInitializationConfig } from './helpers/widgetInitializationConfig'

interface Props {
  isWidgetActiveTab: boolean
  setWidgetSource: Dispatch<SetStateAction<MessageEventSource>>
  setIsShowVoiceButton: Dispatch<SetStateAction<boolean>>
  logOut: () => void
  setIsWidgetStartedPlay: Dispatch<SetStateAction<boolean>>
  setIsWidgetFinishedPlay: Dispatch<SetStateAction<boolean>>
}

interface CustomEventInterface extends MessageEvent {
  data: {
    type: string
    attachmentIcon: string
  }
}

export const Widget: FC<Props> = ({
  isWidgetActiveTab,
  setWidgetSource,
  setIsShowVoiceButton,
  logOut,
  setIsWidgetStartedPlay,
  setIsWidgetFinishedPlay
}) => {
  const widgetContainerRef = useRef(null)
  const { setActiveTab } = useActiveTab()
  const [, setCurrentPage] = useLocalStorage('currentQuestionnairePage', QuestionnairesTypes.ALL_QUESTIONNAIRES)
  const [, setCurrentAttachment] = useLocalStorage('currentAttachment', AttachmentState.ATTACHMENT_PAPER_CLIP_OFF)

  useEffect(() => {
    widgetInitializationConfig()
    window.addEventListener('message', postMessageListener)
    return () => window.removeEventListener('message', postMessageListener)
  }, [])

  const postMessageListener = (event: CustomEventInterface) => {
    const eventType = event.data?.type
    setWidgetSource(event.source)

    switch (eventType) {
      case CustomAttributes.GO_TO_QUESTIONNAIRE_RAQ:
        setCurrentPage(QuestionnairesTypes.RISK_ASSESSMENT)
        setActiveTab(Tabs.TAB_2)
        break
      case CustomAttributes.GO_TO_QUESTIONNAIRES:
        setActiveTab(Tabs.TAB_2)
        break
      case CustomAttributes.GO_TO_QUESTIONNAIRE_FPQ:
        setActiveTab(Tabs.TAB_2)
        setCurrentPage(QuestionnairesTypes.FAMILY_PEDIGREE)
        break
      case CustomAttributes.START_CONVERT_VOICE_TO_TEXT:
        setIsShowVoiceButton(true)
        break
      case CustomAttributes.STOP_CONVERT_VOICE_TO_TEXT:
        setIsShowVoiceButton(false)
        break
      case CustomAttributes.LOG_OUT:
        logOut()
        break
      case CustomAttributes.ATTACHMENT_STATE_CHANGE:
        setCurrentAttachment(event.data.attachmentIcon as AttachmentState)
        break
      case CustomAttributes.WIDGET_FINISHED_PLAY:
        setIsWidgetFinishedPlay(true)
        setIsWidgetStartedPlay(false)
        break
      case CustomAttributes.WIDGET_STARTED_PLAY:
        setIsWidgetFinishedPlay(false)
        setIsWidgetStartedPlay(true)
        break
    }
  }

  return (
    <WidgetContainer
      id='widget-container'
      ref={widgetContainerRef}
      isWidgetActiveTab={isWidgetActiveTab}
    />
  )
}
