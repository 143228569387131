import styled, { css } from 'styled-components';

export const LogoStyled = styled.div`
  width: 100%;
    
  .rutgersLogo {
    margin: 80px 0 0 80px;
    display: flex;
    justify-content: start;
    
    ${props => props.isMobile && css`
      margin: 20px 0 26px 20px;
    `}
  }
  .catalystLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }
`;
