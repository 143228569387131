import styled from 'styled-components';
import { LoginPageContent } from 'src/pages/Login/LoginPage/LoginPage.style';

export const ProvideDataNamesStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  color: ${props => props.theme.colors.additionalText};

  @supports (height: 100svh) {
    min-height: 100svh;
  }
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    
    &:hover {
      background-color: ${props => props.theme.colors.mainButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
  p {
    text-align: center;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`

export const ProvideDataContent = styled(LoginPageContent)`

`

export const ProvideDataContentStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0 66px 0;
  flex-grow: ${props => (props.isMobile ? 1 : 0)};
  
  & > div {
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  label {
    display: inherit;
    justify-content: start;
  }

  input {
    width: ${props => (props.isMobile ? '100%' : '365px')};
    padding: 12px;
    margin-bottom: 0;
  }

  input::placeholder {
    padding: 12px;
  }
`
export const ProvideDataErrorMessage = styled.span`
  color: ${props => props.theme.colors.errorMessage};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-self: flex-start;
  position: absolute;
  bottom: -26px;
`

export const SubmitButton = styled.div`

`
